import React, { ReactElement } from 'react';
import cmsClient from 'lib/cms/client';
import hydrateTemplate from 'lib/cms/links';

import { PlaceType } from 'components/UI/LocationAutocomplete';

import {
  BrandedSiteProps,
  getServerSidePropsWithErrors,
  getContentfulPageData,
  toSlug,
  cacheDirective,
  PageDates,
  getContentfulSiteData,
  isInvalid,
  withSiteData,
  getContentfulPagesData,
  InvalidResponse,
} from 'lib/routing';
import {
  IPageLocationFields,
  IPageFields,
  IPageDoctor,
  IComponentDoctorFinderWithOpenMapFields,
  IMetaLocationsSharedDataFields,
} from 'types/contentful';

import type { ContentType, EntryCollection } from 'contentful';

import {
  DynamicComponentTypes,
  otherParams,
  PreparedComponentObject,
  translateServerSideComponent,
} from 'components';
import { LOCATION_SELECT_FIELDS } from 'lib/consts';
import { ServerResponse, IncomingMessage } from 'http';
import { NextApiRequestCookies } from 'next/dist/server/api-utils';
import DefaultLocationTemplate, {
  DefaultLocationTemplateProps,
} from '../../components/UI/LocationTemplate/Default';
import Template2025, { Template2025Props } from '../../components/UI/LocationTemplate/Template2025';

interface LocationMapPage extends IComponentDoctorFinderWithOpenMapFields {
  locations?: Array<IPageLocationFields>;
}

type SluggedProps<K extends DynamicComponentTypes = DynamicComponentTypes, CP = unknown> = {
  preview: boolean;
  content: PreparedComponentObject<K, CP>[];
  siteData: BrandedSiteProps;
  currentSlug: string;
  title: string;
  description: string;
  noIndexNoFollow: boolean;
  pageDates?: PageDates;
  hideTopNavCtas?: boolean;
};

export type Location = IPageLocationFields & {
  currentDistance?: number;
  sysId?: string;
  current?: boolean;
};

type MapCoord = {
  lat: number;
  lng: number;
};

export type UserLocation = {
  map: MapCoord;
  place?: PlaceType;
};

export default function LocationsIndex<K extends DynamicComponentTypes, CP>(
  props: Template2025Props<K, CP> | DefaultLocationTemplateProps
): ReactElement {
  // Check if props is 2025 template format
  if (props.template === '2025') {
    return <Template2025 {...props} />;
  }

  return <DefaultLocationTemplate {...props} />;
}

type ToRecord<T> = Record<keyof T, T[keyof T]>;

export const getServerSideProps = getServerSidePropsWithErrors<
  SluggedProps | DefaultLocationTemplateProps
>(async ({ resolvedUrl, params, req, res, preview, query }) => {
  const slug = toSlug(params?.slug) || '';
  const queryOptions = {
    'fields.site.sys.contentType.sys.id': 'site',
    'fields.site.fields.id': req.headers.host,
    'fields.slug': 'locations',
    content_type: 'page',
    include: 10, // Make sure we retrieve nav with links
  };

  const siteData = await getContentfulSiteData({ req, res, resolvedUrl, preview });

  if (isInvalid(siteData)) {
    return siteData;
  }

  const contentfulData = await getContentfulPageData<IPageFields>({
    res,
    req,
    preview,
    queryOptions,
  });

  if (isInvalid(contentfulData)) {
    const defaultLocationProps = await getDefaultLocationTemplateData(
      siteData,
      resolvedUrl as string,
      res,
      req,
      preview as boolean
    );
    return defaultLocationProps;
  }

  return get2025LocationTemplateData(
    contentfulData,
    siteData,
    preview as boolean,
    query,
    req,
    res,
    resolvedUrl as string,
    slug
  );
});

async function get2025LocationTemplateData(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contentfulData: any,
  siteData: BrandedSiteProps,
  preview: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query: any,
  req: IncomingMessage & {
    cookies: NextApiRequestCookies;
  },
  res: ServerResponse,
  resolvedUrl: string,
  slug: string
): Promise<{ props: Template2025Props } | InvalidResponse> {
  const client = cmsClient(preview);
  const pageMeta = contentfulData.item.fields;

  // fetch template
  const rawTemplate: ContentType = await client.getContentType(
    pageMeta.template.sys.contentType.sys.id
  );

  // remove symbol fields
  const templateLayout = rawTemplate.fields.filter((field) => field.type !== 'Symbol');

  const searchspringSiteId = siteData?.ecommIds?.fields?.searchSpringId || '';
  query.siteId = searchspringSiteId;
  const freshPaintToken = siteData?.freshPaintToken || '';

  const otherParams: otherParams = {
    query,
    req,
    res,
    resolvedUrl,
    searchspringSiteId,
    freshPaintToken,
  };

  // hydrate and build out template requirements
  const hydrated = await Promise.all(
    hydrateTemplate(
      templateLayout,
      pageMeta.template.fields as ToRecord<typeof pageMeta.template.fields>,
      contentfulData.includes?.Asset || [],
      contentfulData.includes?.Entry || []
    ).map((item) => translateServerSideComponent(item, !!preview, otherParams))
  );

  if (hydrated[0].type == 'componentDoctorFinderWithOpenMap') {
    const fields = hydrated[0].fields as LocationMapPage;
    const locations = fields.locations;

    locations?.forEach((location: IPageLocationFields) => {
      // Check if the location has a `doctors` array
      if (Array.isArray(location.doctors)) {
        location.doctors.forEach((doctor: IPageDoctor) => {
          // remove circular depedency
          delete doctor.fields.primaryLocation;
        });
      }
    });
  }

  res.setHeader('Cache-Control', cacheDirective(preview));

  return {
    props: {
      template: '2025',
      noIndexNoFollow: !!pageMeta.doNotIndex,
      preview: !!preview,
      siteData,
      title: pageMeta.title || '',
      description: pageMeta.description || '',
      content: hydrated,
      currentSlug: slug,
      pageDates: {
        datePublished: contentfulData.item.sys.createdAt,
        dateModified: contentfulData.item.sys.updatedAt,
      },
      hideTopNavCtas: pageMeta.hideTopNavCtas || false,
    },
  };
}

async function getDefaultLocationTemplateData(
  siteData: BrandedSiteProps,
  resolvedUrl: string,
  res: ServerResponse,
  req: IncomingMessage & {
    cookies: NextApiRequestCookies;
  },
  preview: boolean
): Promise<{ props: DefaultLocationTemplateProps } | InvalidResponse> {
  const host = req.headers.host;
  const queryOptions = {
    'fields.site.sys.contentType.sys.id': 'site',
    'fields.site.fields.id': host,
    limit: 1000,
    select: LOCATION_SELECT_FIELDS,
    content_type: 'pageLocation',
  };

  const contentfulData = await getContentfulPagesData<IPageLocationFields>({
    res,
    req,
    preview,
    queryOptions,
  });

  if (isInvalid(contentfulData)) {
    return withSiteData(contentfulData, siteData);
  }

  const client = cmsClient(preview);
  const locations = contentfulData.items;

  const sharedMeta: EntryCollection<IMetaLocationsSharedDataFields> = await client.getEntries({
    'fields.site.sys.contentType.sys.id': 'site',
    'fields.site.fields.id': host,
    select:
      'fields.officeFinderHero,fields.banners,fields.scheduleApptCtaText,fields.hideEyeIcon,fields.markerPinIcon',
    content_type: 'metaLocationsSharedData',
    include: 4,
  });

  res.setHeader('Cache-Control', cacheDirective(preview));

  return {
    props: {
      template: 'default',
      title: 'Find a Location Near You',
      hero: sharedMeta?.items[0]?.fields?.officeFinderHero?.fields || null,
      locationBanners: sharedMeta?.items[0]?.fields?.banners || [],
      scheduleApptCtaText: sharedMeta?.items[0]?.fields?.scheduleApptCtaText || '',
      preview: preview ? true : false,
      locations: locations.map((loc) => ({ ...loc.fields, sysId: loc.sys.id })),
      currentSlug: 'locations',
      siteData,
    },
  };
}
