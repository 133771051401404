import React, { ReactElement, useEffect, useRef, useState, MutableRefObject } from 'react';
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
import ListIcon from '@material-ui/icons/List';
import { BrandedSiteProps } from 'lib/routing';
import {
  IPageLocationFields,
  IComponentHeroInlineCircleFields,
  IFragmentLocationBanner,
} from 'types/contentful';

import Layout from 'components/UI/Layout';
import LocationAutocomplete, { PlaceType } from 'components/UI/LocationAutocomplete';
import LocationCard from 'components/UI/LocationCard';
import NoResults from 'components/UI/NoResults';
import HeroInlineCircle from 'components/Contentful/Hero/HeroInlineCircle';
import SharedLocationDataContext from 'context/SharedLocationDataContext';
import { addItemToLocalStorage, getDistance } from 'lib/util';
import MapComponent from 'components/UI/Map';

const DEFAULT_RADIUS = 100;
const DEFAULT_LOCATION_ZOOM = 10;
// const INITIAL_MAP_ZOOM = 4;

const RADII = [5, 10, 20, 35, 50, 100];

const SAVED_LOCATION_KEY = 'finder-saved-location';
const SAVED_RADIUS_KEY = 'finder-saved-radius';
const SAVED_MOBILE_MAP_VIEW = 'saved-mobile-view';

export type Location = IPageLocationFields & {
  currentDistance?: number;
  sysId?: string;
  current?: boolean;
};

type MapCoord = {
  lat: number;
  lng: number;
};

export type UserLocation = {
  map: MapCoord;
  place?: PlaceType;
};

export type DefaultLocationTemplateProps = {
  template: 'default';
  preview: boolean;
  hero: IComponentHeroInlineCircleFields | null;
  locations: Location[];
  siteData: BrandedSiteProps;
  currentSlug: string;
  title: string;
  locationBanners: IFragmentLocationBanner[];
  scheduleApptCtaText: string;
};

export default function DefaultLocationTemplate({
  preview,
  title,
  currentSlug,
  siteData,
  hero,
  locations,
  locationBanners,
  scheduleApptCtaText,
}: DefaultLocationTemplateProps): ReactElement {
  const [radius, setRadius] = useState<number>(DEFAULT_RADIUS);
  const [userLocation, setUserLocation] = useState<UserLocation | null>(null);
  const [foundLocations, setFoundLocations] = useState(locations);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [showPopUp, setShowPopUp] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [activeLocation, setActiveLocation] = useState<any>(null);
  const [searched, setSearched] = useState(false);
  const [showMapView, setShowMapView] = useState(false);
  const mapInstance = useRef() as MutableRefObject<google.maps.Map>;
  // const geoCoder = useRef() as MutableRefObject<google.maps.Geocoder>;
  const mapContainer = useRef() as MutableRefObject<HTMLDivElement>;

  const setInitialSearchPosition = (userLocation: UserLocation | null): void => {
    if (!mapInstance.current || userLocation === null) return undefined;
    mapInstance.current.setCenter(
      new google.maps.LatLng(userLocation.map.lat, userLocation.map.lng)
    );
    mapInstance.current.setZoom(DEFAULT_LOCATION_ZOOM);
  };

  /**
   * Sets initial zoom/position when a user location is selected.
   */
  useEffect(() => {
    setInitialSearchPosition(userLocation);
  }, [userLocation]);

  /**
   * When radius or user's selected location changes,
   * find all locations within range and sort.
   */
  useEffect(() => {
    if (userLocation === null) {
      setFoundLocations(locations);
      return;
    }

    const nearby = locations
      .map((loc) => {
        const coords = loc.map;
        const distance = getDistance(
          userLocation.map.lat,
          userLocation.map.lng,
          coords.lat,
          coords.lon
        );

        return { ...loc, currentDistance: distance };
      })
      .filter((loc) => loc.currentDistance <= radius)
      .sort((a, b) => a.currentDistance - b.currentDistance);

    if (nearby.length) {
      addItemToLocalStorage(SAVED_LOCATION_KEY, JSON.stringify(userLocation));
      addItemToLocalStorage(SAVED_RADIUS_KEY, radius.toString());
    }

    setFoundLocations(nearby);
    setSearched(true);
  }, [locations, radius, userLocation]);

  /**
   * This callback is triggered when the map and maps api have been successfully loaded.
   * @param param
   */
  // const googleApiLoaded = ({ map }: { map: google.maps.Map }): void => {
  //   setAutocompleteService(new google.maps.places.AutocompleteService());
  //   geoCoder.current = new google.maps.Geocoder();
  //   mapInstance.current = map;
  //   google.maps.event.trigger(map, 'resize');
  //   const savedLocation = getItemFromLocalStorage(SAVED_LOCATION_KEY);
  //   const savedRadius = getItemFromLocalStorage(SAVED_RADIUS_KEY);
  //   const savedMobileMapView = getItemFromLocalStorage(SAVED_MOBILE_MAP_VIEW);
  //   if (savedLocation) setUserLocation(JSON.parse(savedLocation));
  //   if (savedRadius) setRadius(Number(savedRadius));
  //   if (savedMobileMapView) setShowMapView(savedMobileMapView === 'true');
  // };

  /**
   * Get location from autocomplete and geocode it.
   * @param place
   * @returns
   */
  const locationSelected = (place: PlaceType): void => {
    if (place === null) return undefined;

    setUserLocation({ place, map: { lat: place.lat, lng: place.lng } });
  };

  const onUseMyLocation = (coords: GeolocationCoordinates): void => {
    setUserLocation({ map: { lat: coords.latitude, lng: coords.longitude } });
  };

  /**
   * Reset map data on show map view toggle
   */
  const toggleMapView = (showMapView: boolean): void => {
    if (showMapView) {
      setShowPopUp(true);
      setActiveLocation(null);
    }
    addItemToLocalStorage(SAVED_MOBILE_MAP_VIEW, showMapView.toString());
    setShowMapView(showMapView);
  };

  const nextSeoProps = {
    title,
    description: `Use our interactive map to browse all of our ${siteData.siteName} locations and find the office closest to you. Then, book an appointment with one of our trusted eye doctors!`,
  };

  return (
    <Layout
      siteData={siteData}
      preview={preview}
      currentSlug={currentSlug}
      nextSeoProps={nextSeoProps}
    >
      <SharedLocationDataContext.Provider
        value={{ scheduleAppointmentCtaText: scheduleApptCtaText }}
      >
        {hero && (
          <div className="mb-6">
            <HeroInlineCircle {...hero} />
          </div>
        )}
        <div className="flex flex-col lg:flex-row mb-10">
          <div className="w-full lg:w-3/5">
            <div className="flex flex-col py-5 sm:flex-row">
              {
                <LocationAutocomplete
                  className="mx-5 mb-5 sm:mb-0 sm:w-2/3"
                  onSelect={locationSelected}
                  onUseMyLocation={onUseMyLocation}
                  initValue={userLocation?.place}
                  freshPaintToken={siteData.freshPaintToken}
                />
              }
              {searched && (
                <span className="flex items-center shadow-lg mx-5 px-5 py-3 sm:w-1/3 h-56">
                  <label className="font-bold" htmlFor="search-radius">
                    Radius:
                  </label>
                  <select
                    id="search-radius"
                    value={radius}
                    onChange={({ target }) => setRadius(parseFloat(target.value))}
                    className="w-full"
                  >
                    {RADII.map((opt) => (
                      <option key={`radius-${opt}`} value={opt}>
                        {opt} miles
                      </option>
                    ))}
                  </select>
                </span>
              )}
            </div>
            <div
              className={`${showMapView ? 'block' : 'hidden'} flex justify-between lg:hidden p-3`}
            >
              <span className="font-bold text-sm opacity-50">
                {foundLocations.length && searched ? (
                  <>
                    {foundLocations.length} Results within {radius} miles
                  </>
                ) : (
                  <>{locations.length} Results Nationwide</>
                )}
              </span>
              <a
                tabIndex={0}
                role="button"
                className="block lg:hidden"
                onClick={() => toggleMapView(!showMapView)}
                onKeyDown={() => toggleMapView(!showMapView)}
              >
                <ListIcon /> List View
              </a>
            </div>
            <div
              className={`lg:h-map relative relative`}
              ref={mapContainer}
              style={{ height: showMapView ? '700px' : '' }}
            >
              <MapComponent
                userLocation={userLocation}
                foundLocations={foundLocations}
                showPopUp={showPopUp}
                activeLocation={activeLocation}
                setActiveLocation={setActiveLocation}
                renderInfoCard={'MapCard'}
                defaultCenter={{ lat: 37.7749, lng: -122.4194 }} // Default center on San Francisco
              />
              {/* <Map
                defaultZoom={INITIAL_MAP_ZOOM}
                center={userLocation?.map || MAP_DEFAULTS.center}
                // onApiLoaded={googleApiLoaded}
                forceSetMapBounds={showMapView}
              >
                {userLocation && (
                  <MapMarker
                    key={'finder-user-location'}
                    lat={userLocation.map.lat}
                    lng={userLocation.map.lng}
                    style={{ zIndex: 20 }}
                  >
                    <UserMarkerSvg classNames="text-secondary-actual" />
                  </MapMarker>
                )}
                {foundLocations.map((loc) => (
                  <MapMarker
                    key={`marker-${loc.slug}`}
                    lat={loc.map.lat}
                    lng={loc.map.lon}
                    onClick={() => {
                      setShowPopUp(activeCard !== loc ? loc : null);
                      setActiveLocation(activeLocation !== loc ? loc : null);
                    }}
                    focused={activeLocation === loc}
                    renderInfoCard={() => <MapCard location={loc} />}
                    showCard={activeCard === loc}
                  >
                    <LocationMarkerSvg
                      title={loc.name}
                      hideEyeOnPin={!!hideEyeOnPin}
                      classNames={
                        loc === activeLocation ? 'text-primary-actual' : 'text-tertiary-actual'
                      }
                      focused={activeLocation === loc}
                      markerIcon={markerIcon}
                    />
                  </MapMarker>
                ))}
              </Map> */}
            </div>
          </div>
          <div
            className={`${showMapView ? 'hidden' : 'block'} lg:block lg:w-2/5 lg:pl-16 lg:mt-20`}
          >
            <div className="flex justify-between mb-5 border-b-2 p-3">
              <span className="font-bold text-sm opacity-50">
                {foundLocations.length && searched ? (
                  <>
                    {foundLocations.length} Results within {radius} miles
                  </>
                ) : (
                  <>{locations.length} Results Nationwide</>
                )}
              </span>
              <a
                tabIndex={0}
                role="button"
                className="block lg:hidden"
                onClick={() => toggleMapView(!showMapView)}
                onKeyDown={() => toggleMapView(!showMapView)}
              >
                <ExploreOutlinedIcon /> Map View
              </a>
            </div>
            {foundLocations.length ? (
              <div className="lg:h-finder-overflow lg:overflow-y-auto divide-y-2 lg:p-2">
                {foundLocations.map(
                  (location): ReactElement => (
                    <div
                      key={`office-location-${location.slug}`}
                      className="transition ease-linear duration-100 shadow-none lg:hover:shadow-location-card"
                      onFocus={() => {
                        setActiveLocation(location);
                        setShowPopUp(false);
                      }}
                      onBlur={() => {
                        setActiveLocation(null);
                        setShowPopUp(true);
                      }}
                      onMouseOver={() => {
                        setActiveLocation(location);
                        setShowPopUp(false);
                      }}
                      onMouseLeave={() => {
                        setActiveLocation(null);
                        setShowPopUp(true);
                      }}
                      onTouchStart={() => {
                        setActiveLocation(location);
                        setShowPopUp(false);
                      }}
                      onTouchCancel={() => {
                        setActiveLocation(null);
                        setShowPopUp(true);
                      }}
                    >
                      <LocationCard locationBanners={locationBanners} location={location} />
                    </div>
                  )
                )}
              </div>
            ) : (
              <div className="flex justify-center content-center">
                <NoResults />
                <p className="ml-5 text-center flex flex-col justify-center">
                  {searched && foundLocations.length === 0 && (
                    <>
                      <span className="font-bold">
                        Whoops,
                        <br />
                        no results found in your area
                      </span>
                      <br />
                      Please try a different search location.
                    </>
                  )}
                  {!searched && <span className="font-bold">Type above to start a search.</span>}
                </p>
              </div>
            )}
          </div>
        </div>
      </SharedLocationDataContext.Provider>
    </Layout>
  );
}
