import React, { ReactElement } from 'react';
import Layout from 'components/UI/Layout';
import { buildDynamicComponent, DynamicComponentTypes, PreparedComponentObject } from 'components';
import { BrandedSiteProps, PageDates } from 'lib/routing';

export interface Template2025Props<
  K extends DynamicComponentTypes = DynamicComponentTypes,
  CP = unknown
> {
  template: '2025';
  preview: boolean;
  content: PreparedComponentObject<K, CP>[];
  siteData: BrandedSiteProps;
  currentSlug: string;
  title: string;
  description: string;
  noIndexNoFollow: boolean;
  pageDates?: PageDates;
  hideTopNavCtas?: boolean;
}

export default function Template2025<K extends DynamicComponentTypes, CP>({
  preview,
  content,
  siteData,
  currentSlug,
  title,
  description,
  noIndexNoFollow,
  pageDates,
  hideTopNavCtas,
}: Template2025Props<K, CP>): ReactElement {
  const components = content.map((comp: PreparedComponentObject<K, CP>, index: number) => {
    const dynamicComp = buildDynamicComponent(comp, siteData.siteName, preview);
    if (dynamicComp === undefined) return;

    return <div key={index}>{dynamicComp}</div>;
  });

  const nextSeoProps = {
    description,
    title,
  };

  return (
    <Layout
      noIndexNoFollow={noIndexNoFollow}
      siteData={siteData}
      preview={preview}
      currentSlug={currentSlug}
      nextSeoProps={nextSeoProps}
      pageDates={pageDates}
      hideTopNavCtas={hideTopNavCtas}
    >
      <div>{components}</div>
    </Layout>
  );
}
